.container {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 1rem 0;
}
.pagination {
  display: flex;
  padding: 0;
  margin: 0;

  & * {
    text-decoration: none;
    list-style: none;
    border: none;
  }
  .next {
    margin-left: 0.5rem;
  }
}
@media screen and (min-width: 1280px) {
  .page:hover,
  .break:hover,
  .next:hover,
  .previous:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.active {
  background-color: var(--color-primary) !important;
}
.page,
.break {
  height: 2.5rem;
  width: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 0.5rem;
  border-radius: 40%;

  color: white;
  text-align: center;
  font-size: var(--small-l);
  background-color: var(--color-magenta);
}
.icon {
  fill: white;
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--color-magenta);
  border-radius: 40%;
}
path {
  padding: 0;
  margin: 0;
}

@media all and (max-width: 40em) and (orientation: portrait) {
  .page,
  .break,
  .icon {
    height: 2rem;
    width: 2rem;
  }
}
